<template>
  <div>
    <div v-for="(photo, photoIndex) in photosLocal" :key="photoIndex"><img :src="photo.webviewPath" alt="" /></div>
    <bottom-bar />
  </div>
</template>

<script>
import BottomBar from '../components/BottomBar.vue';

export default {
  components: {
    BottomBar,
  },
  data() {
    return {
      photosLocal: [],
    };
  },
  created() {
    this.photosLocal = this.Photos;
  },
  computed: {
    Photos: {
      get: function() {
        return this.$store.state.Photos;
      },
      set: function(newValue) {
        return this.$store.dispatch('setPhotos', newValue);
      },
    },
  },
};
</script>

<style></style>
